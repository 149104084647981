import React from "react"
import Header from "../components/header"
import Heading from "../components/heading"
import Nav from "../components/nav"
import styles from "../static/bloghead.module.css"
import { Link } from "gatsby"



export default function PostOne() {

  return (
    <div>
        <Header>
            <Heading title='Blog' href='/' linkText='Home' />
            <Nav />
        </Header>
    <div className={styles.page}>
    	<Link to="/blog" className={styles.back}>Back to blog</Link>
    	<h1 className={styles.title}>My Lateness Problem</h1>
    	<p className={styles.post}>
    		It comes up everywhere. Recently and most often it’s leaving for class. I used to have
            Chinese every day, in the same room, in the same building (and it’s decently far away).
             After two semesters of this, you’d think I would have a nice schedule for leaving my dorm. 
             Not quite. Let’s set the hypothetical start time of 10am. I need to leave at 9:45am on the dot.
              But somehow no matter what I tell myself, I just don’t put in the effort to actually ensure 
              that I can leave then. 

    		<br></br><br></br>

    		Something always comes up. Most days I’ll just need another two minutes to brush my teeth or 
            finish reading an email. And I think, well even if I do leave later than planned, I actually 
            did that yesterday and it worked out fine because I just speed walked. So then I end up leaving
            at 9:50 and make it work. Clearly, a new problem then arises. I start leaving at 9:50 every day, 
            because that works, right? 

    		<br></br><br></br>

    		Wrong. Because then I’m a few minutes late to leaving at that time, and I leave with only six or seven minutes 
            to awkwardly half-run with my backpack bouncing up and down. I start getting super
             sweaty so alternate walking and jogging, but it’s freezing outside so it feels kind of good. But then I 
             enter the Chinese building with a pumping heart, and for some reason that building is always 
             pushing 80 degrees, and I have to climb two flights of stairs to get to my room. Considering I’ve just 
             been booking it to class, and the temperature is extremely different from outside, and I don’t
              have time to take off my coat because I’m cutting it so close and don’t want to miss the
               dictation quiz, by the time I’m at my seat right at 10am I’m so sweaty and as I disrobe
               all of my layers, I think about what went wrong and I’m so busy planning on how to leave on 
               time for the next day that my mental capacity for remembering Chinese vocab words is slim.

    		<br></br><br></br>

    		Sometimes things go perfectly. I leave with a cool thirteen minutes to spare – just a nice walk 
            to enjoy on the way to class. WHAM. Of course, I have to suddenly shit. I poop every morning
             on the dot, right after drinking some coffee. But of course, some days there will just be an 
             unexpected second poop that feels like tiny little trolls are forcing their way out of me
              – what am I supposed to do, hold it for an hour of Chinese?  不可以

    		<br></br><br></br>

    		In high school, I heard a church sermon about how Lent isn’t just about giving up vices, but giving up vices 
            to make room for better things in life. So, one year, I "gave up" being late in order to
            live a lower-stress life while also being a safer driver (that is, not having to speed to make it places
                on time). Plus, I always hated feeling
             like I’d let my teachers down being a few minutes late to class. Ash Wednesday came and went...but Lent didn’t change anything. 
             Once again I would leave home with little time time to spare, and I would only drive faster 
             because, damn, I'm giving this up for Lent so now I really can’t be late for school. Otherwise both my teacher
             and God will punish me. Furthermore, the aggressiveness of my driving has to ease up the closer 
             I get to school (since people on the road have an increasingly higher chance to be school staff, 
             and I don’t want to come off as a dangerous driver, of course). All to say, I would really need
              to floor it early on. 

    		<br></br><br></br>

    		Conclusion to this post coming soon...

    	</p>
    </div>
    </div>
  )
 }